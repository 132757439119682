import api from '../helpers';

export default {
  getFirmware: {
    key: 'firmware',
    query: (serial) => {
      return api.get('/api/firmware', {
        responseType: 'blob',
        headers: {
          Accept: 'application/binary',
          'Content-Type': 'application/json',
        },
        params: {
          serial,
        },
        _throwError: true,
      });
    },
  },
};
