import { COMPRESSOR_FIELD_NAMES } from 'src/constants/compressorFields';
import { PERFORMANCE_INDICATORS } from 'src/constants/enpisFields';
import { RECORD_FIELD_NAMES } from 'src/constants/recordFields';
import Unit from 'src/constants/unit';

const Conversion = {
  FLOW_RATIO: 1.69901,
  FEET_PER_METER: 3.28084,
  FC_RATIO: 5 / 9,
  FC_MINUS: 32,
  PRESSURE_RATIO: 6.8947572932,
  POWER_RATIO: 0.98632,
  FT3_PER_M3: 35.3147,
  STORAGE_RATIO: 3.78541,
  POWER_RATIO_KW: 3412.14,
};

function CFMtoCMH(value) {
  return value * Conversion.FLOW_RATIO;
}

function CMHToCFM(value) {
  return value / Conversion.FLOW_RATIO;
}

function FToC(value) {
  const temp = value - Conversion.FC_MINUS;
  return temp * Conversion.FC_RATIO;
}

function CToF(value) {
  const temp = value * (1 / Conversion.FC_RATIO);
  return temp + Conversion.FC_MINUS;
}

function PsiToKpa(value) {
  return value * Conversion.PRESSURE_RATIO;
}

function KpaToPsi(value) {
  return value / Conversion.PRESSURE_RATIO;
}

function FeetToMeter(value) {
  return value / Conversion.FEET_PER_METER;
}

function MeterToFeet(value) {
  return value * Conversion.FEET_PER_METER;
}

function MBHPToIBHP(value) {
  return value / Conversion.POWER_RATIO;
}

function IBHPToMBHP(value) {
  return value * Conversion.POWER_RATIO;
}

function CMDollarsToCFtDollars(value) {
  return value * Conversion.FT3_PER_M3;
}

function CftDollarstoCMDollars(value) {
  return value / Conversion.FT3_PER_M3;
}

function LitresToUSGallons(value) {
  return value * Conversion.STORAGE_RATIO;
}

function USGallonsToLitres(value) {
  return value / Conversion.STORAGE_RATIO;
}

function kWToBTUh(value) {
  return value * Conversion.POWER_RATIO_KW;
}

function BTUhToKW(value) {
  return value / Conversion.POWER_RATIO_KW;
}

function convertUnit(columnName, value, unit) {
  switch (columnName) {
    case RECORD_FIELD_NAMES.FLOW:
    case RECORD_FIELD_NAMES.COMPRESSOR_FLOW:
    case COMPRESSOR_FIELD_NAMES.NAMEPLATE_CAPACITY:
    case COMPRESSOR_FIELD_NAMES.COMPRESSOR_FLOW_SEU:
    case COMPRESSOR_FIELD_NAMES.SYSTEM_MEASURED_FLOW:
    case `ENPI_${PERFORMANCE_INDICATORS.VOL_FLOW_FOUND}`:
    case `ENB_${PERFORMANCE_INDICATORS.VOL_FLOW_FOUND}`:
    case `ENPI_${PERFORMANCE_INDICATORS.VOL_FLOW_REPAIRED}`:
    case `ENB_${PERFORMANCE_INDICATORS.VOL_FLOW_REPAIRED}`:
      return unit === Unit.METRIC ? CFMtoCMH(value) : CMHToCFM(value);

    case RECORD_FIELD_NAMES.LEAK_TEMPERATURE:
    case RECORD_FIELD_NAMES.AMBIENT_TEMPERATURE:
      return unit === Unit.METRIC ? FToC(value) : CToF(value);

    case RECORD_FIELD_NAMES.LEAK_PRESSURE:
    case COMPRESSOR_FIELD_NAMES.RATED_PRESSURE:
    case COMPRESSOR_FIELD_NAMES.PRESSURE_SETPOINT:
    case COMPRESSOR_FIELD_NAMES.COMPRESSOR_SETPOINT_SEU:
    case COMPRESSOR_FIELD_NAMES.SYSTEM_PRESSURE_SETPOINT:
    case `ENPI_${PERFORMANCE_INDICATORS.SYSTEM_CONTROL_PRESSURE_REDUCED}`:
    case `ENB_${PERFORMANCE_INDICATORS.SYSTEM_CONTROL_PRESSURE_REDUCED}`:
      return unit === Unit.METRIC ? PsiToKpa(value) : KpaToPsi(value);

    case COMPRESSOR_FIELD_NAMES.TOTAL_AIR_STORAGE_SIZE:
      return unit === Unit.METRIC ? LitresToUSGallons(value) : USGallonsToLitres(value);

    case `ENPI_${PERFORMANCE_INDICATORS.ENERGY_SAVING_OPPORTUNITY_FOUND}`:
    case `ENB_${PERFORMANCE_INDICATORS.ENERGY_SAVING_OPPORTUNITY_FOUND}`:
    case `ENPI_${PERFORMANCE_INDICATORS.ENERGY_SAVING_REALIZED_FIXED}`:
    case `ENB_${PERFORMANCE_INDICATORS.ENERGY_SAVING_REALIZED_FIXED}`:
    case COMPRESSOR_FIELD_NAMES.FULL_LOAD_INPUT_POWER:
      return unit === Unit.METRIC ? kWToBTUh(value) : BTUhToKW(value);

    case RECORD_FIELD_NAMES.DISTANCE:
    case RECORD_FIELD_NAMES.ALTITUDE:
      return unit === Unit.METRIC ? FeetToMeter(value) : MeterToFeet(value);

    case RECORD_FIELD_NAMES.COMPRESSOR_BHP:
      return unit === Unit.METRIC ? IBHPToMBHP(value) : MBHPToIBHP(value);

    case RECORD_FIELD_NAMES.GAS_COST:
      return unit === Unit.METRIC ? CMDollarsToCFtDollars(value) : CftDollarstoCMDollars(value);

    default:
      return value;
  }
}

export function centsToDollars(value) {
  const cents = parseInt(value, 10);
  return !Number.isNaN(cents) ? Number(cents / 100) : 0.0;
}

export function dollarsToCents(value) {
  // use .toFixed(2) for 'truncate' non meaningful digits
  const cents = Number(value).toFixed(2) * 100;
  return !Number.isNaN(cents) && Number.isFinite(cents) ? cents : 0;
}

export default convertUnit;
