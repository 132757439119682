export const FEATURES_LIST = {
  // Assigned Action Records
  AAR: 'AAR',
  // Notifications
  NTF: 'NTF',
  // Logout Buttons
  LOGOUT: 'LOGOUT',
  // Invite User To Company
  INVITE_USER: 'INVITE_USER',
  REGISTER_DEVICE: 'REGISTER_DEVICE',
  // View Company Page
  VIEW_COMPANIES: 'VIEW_COMPANIES',
  EDIT_PROFILE: 'EDIT_PROFILE',
  TAG_BANNER: 'TAG_BANNER',
  DASHBOARD_LOGO: 'DASHBOARD_LOGO',
  SITE_VERSION: 'SITE_VERSION',
  // Translation
  TRANSLATION: 'TRANSLATION',
  // For downloading APKs from the dashboard
  DOWNLOAD_APK: 'DOWNLOAD_APK',
  // For downloading OTAs from the dashboard
  DOWNLOAD_OTA: 'DOWNLOAD_OTA',
};

export const FEATURES = Object.keys(FEATURES_LIST);
