export default {
  // Users
  VIEW_USERS: 'view_users',
  EDIT_USER: 'edit_user',
  EDIT_USER_PASSWORD: 'edit_user_password',
  CREATE_USER: 'create_user',
  DELETE_USER: 'delete_user',
  DELETE_OWN_USER: 'delete_own_user',

  // Companies
  VIEW_COMPANIES: 'view_companies',
  EDIT_COMPANY: 'edit_company',
  CREATE_COMPANY: 'create_company',
  DELETE_COMPANY: 'delete_company',

  // Devices
  VIEW_COMPANY_DEVICES: 'view_company_devices',
  EDIT_COMPANY_DEVICE: 'edit_company_device',
  EDIT_DEVICE_ACTIVATION_DATE: 'edit_device_activation_date',

  // Company Settings
  VIEW_COMPANY_SETTINGS: 'view_company_settings',
  EDIT_COMPANY_SETTINGS: 'edit_company_settings',

  // Leak Records
  VIEW_LEAK_RECORDS: 'view_records',
  EDIT_LEAK_RECORD: 'edit_record',
  BULK_EDIT_LEAK_RECORDS: 'bulk_edit_records',
  CREATE_LEAK_RECORD: 'create_record',
  CREATE_MANUAL_LEAK_RECORD: 'create_manual_record',
  DELETE_LEAK_RECORD: 'delete_record',

  // Own Profile & Leak Records & Company
  VIEW_OWN_PROFILE: 'view_own_profile',
  VIEW_OWN_COMPANY: 'view_own_company',
  EDIT_OWN_PROFILE: 'edit_own_profile',
  EDIT_OWN_COMPANY: 'edit_own_company',

  VIEW_OWN_LEAK_RECORDS: 'view_own_records',
  EDIT_OWN_LEAK_RECORD: 'edit_own_record',
  DELETE_OWN_LEAK_RECORD: 'delete_own_record',

  // Sub-company Users
  VIEW_CHILD_USERS: 'view_child_users',
  EDIT_CHILD_USER: 'edit_child_user',
  CREATE_CHILD_USER: 'create_child_user',
  DELETE_CHILD_USER: 'delete_child_user',

  // Sub-companies
  VIEW_CHILD_COMPANIES: 'view_child_companies',
  EDIT_CHILD_COMPANY: 'edit_child_company',
  CREATE_CHILD_COMPANY: 'create_child_company',
  DELETE_CHILD_COMPANY: 'delete_child_company',

  // Sub-company Leak Records
  VIEW_CHILD_LEAK_RECORDS: 'view_child_records',
  EDIT_CHILD_LEAK_RECORD: 'edit_child_record',
  CREATE_CHILD_LEAK_RECORD: 'create_child_record',
  DELETE_CHILD_LEAK_RECORD: 'delete_child_record',

  // Features
  VIEW_FEATURE: 'view_feature',
  EDIT_FEATURE: 'edit_feature',
  CREATE_FEATURE: 'create_feature',
  DELETE_FEATURE: 'delete_feature',

  // Notifications
  VIEW_NOTIFICATIONS: 'view_notifications',
  EDIT_NOTIFICATIONS: 'edit_notifications',
  DELETE_NOTIFICATIONS: 'delete_notifications',

  // Assignments
  VIEW_ASSIGNMENT: 'view_assignment',
  CREATE_ASSIGNMENT: 'create_assignment',
  UPDATE_ASSIGNMENT: 'update_assignment',
  OWN_ASSIGNMENT: 'own_assignment',
  DELETE_ASSIGNMENT: 'delete_assignment',

  // own assignments
  VIEW_OWN_ASSIGNMENT: 'view_own_assignment',
  UPDATE_OWN_ASSIGNMENT: 'update_own_assignment',
  DELETE_OWN_ASSIGNMENT: 'delete_own_assignment',
  // Own Leak Images
  VIEW_OWN_RECORD_IMAGES: 'view_own_record_images',
  EDIT_OWN_RECORD_IMAGE: 'edit_own_record_image',
  CREATE_OWN_RECORD_IMAGE: 'create_own_record_image',
  DELETE_OWN_RECORD_IMAGE: 'delete_own_record_image',

  // Leak Images
  VIEW_RECORD_IMAGES: 'view_record_images',
  EDIT_RECORD_IMAGE: 'edit_record_image',
  CREATE_RECORD_IMAGE: 'create_record_image',
  DELETE_RECORD_IMAGE: 'delete_record_image',

  // Own Assignment Images
  VIEW_OWN_ASSIGNMENT_IMAGES: 'view_own_assignment_images',
  EDIT_OWN_ASSIGNMENT_IMAGE: 'edit_own_assignment_image',
  CREATE_OWN_ASSIGNMENT_IMAGE: 'create_own_assignment_image',
  DELETE_OWN_ASSIGNMENT_IMAGE: 'delete_own_assignment_image',

  // Assignment Images
  VIEW_ASSIGNMENT_IMAGES: 'view_assignment_images',
  EDIT_ASSIGNMENT_IMAGE: 'edit_assignment_image',
  CREATE_ASSIGNMENT_IMAGE: 'create_assignment_image',
  DELETE_ASSIGNMENT_IMAGE: 'delete_assignment_image',

  // Exports
  EXPORT_TAGS: 'export_tags',
  EXPORT_LEAK_RECORDS: 'export_leak_records',

  // Reports/charts
  VIEW_REPORT: 'view_report',

  // History
  VIEW_HISTORY: 'view_history',

  // Auditing
  CREATE_AUDIT: 'create_audit',
  VIEW_AUDIT: 'view_audit',
  EDIT_AUDIT: 'edit_audit',
  LOCK_RECORD: 'lock_record',
  DELETE_AUDIT: 'delete_audit',

  // Compressors
  EDIT_COMPANY_COMPRESSOR: 'edit_company_compressor',
  CREATE_COMPANY_COMPRESSOR: 'create_company_compressor',
  DELETE_COMPANY_COMPRESSOR: 'delete_company_compressor',

  // Nameplates
  CREATE_COMPRESSOR_NAMEPLATE: 'create_compressor_nameplate',
  DELETE_COMPRESSOR_NAMEPLATE: 'delete_compressor_nameplate',

  // Datasheets
  CREATE_COMPRESSOR_DATASHEET: 'create_compressor_datasheet',
  DELETE_COMPRESSOR_DATASHEET: 'delete_compressor_datasheet',

  // Enpis
  EDIT_COMPANY_ENPIS: 'edit_company_enpis',
  CREATE_COMPANY_ENPIS: 'create_company_enpis',
};
