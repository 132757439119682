import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IMPERIAL_LABELS, DEFAULT_LABELS, RECORD_FIELD_NAMES } from 'src/constants/recordFields';
import Unit from 'src/constants/unit';

const UnitContext = createContext({
  unit: Unit.METRIC,
  labels: {},
  showUnitToggle: false,
  setShowUnitToggle: () => {},
  setUnit: () => {},
  setOffset: () => {},
  offset: {},
});

export function UnitProvider({ children }) {
  const { t } = useTranslation();
  const languageLabels = t('constants.recordFields', { returnObject: true });
  const [unit, setUnit] = useState({ value: Unit.METRIC, changed: false });
  const [showUnitToggle, setShowUnitToggle] = useState(false);
  const [labels, setLabels] = useState({ languageLabels });
  const [offset, setOffset] = useState({});

  function handleUnitChange(newUnit) {
    if (newUnit === Unit.METRIC) {
      const languageLabels = t('constants.recordFields', { returnObject: true });

      setLabels(languageLabels);
    } else {
      const languageImperialLabels = t('constants.IMPERIAL_LABELS', { returnObject: true });
      const unitLabels = {};

      const keys = Object.keys(DEFAULT_LABELS);

      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];

        unitLabels[key] = newUnit === Unit.IMPERIAL && IMPERIAL_LABELS[key] ? languageImperialLabels[key] : languageLabels[key];
      }

      setLabels(unitLabels);
    }

    setUnit({ value: newUnit, changed: true });
  }

  function handleOffset(key, value) {
    const update = { ...offset };
    update[key] = value;
    setOffset(update);
  }

  useEffect(() => {
    handleUnitChange(unit.value);
  }, [unit.value, t]);

  const contextValue = useMemo(() => ({
    unit: unit.value,
    unitChanged: unit.changed,
    labels,
    setUnit: handleUnitChange,
    showUnitToggle,
    setShowUnitToggle,
    setOffset: handleOffset,
    offset,
  }));

  return <UnitContext.Provider value={contextValue}>{children}</UnitContext.Provider>;
}

UnitProvider.propTypes = {
  children: PropTypes.node,
};

export default UnitContext;
