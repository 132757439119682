import api from '../helpers';

export default {
  register: {
    key: 'deviceId',
    query: (payload) => {
      return api.get('/api/devices', {
        params: {
          deviceId: payload.deviceId,
        },
        _throwError: true,
      });
    },
  },
  registerDevices: {
    key: 'registerDevices',
    query: (deviceIds) => {
      return api.post('/api/devices', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { deviceIds },
        _throwError: true,
      });
    },
  },
  getDevices: {
    key: 'fetchDevices',
    query: (companyId, offset = 0, limit = -1) => {
      return api.get(`/api/devices/${companyId}?offset=${offset}&limit=${limit}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => model);
    },
  },
  edit: {
    key: 'editDevice',
    query: (id, data) => {
      return api.put(`/api/devices/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        _throwError: true,
      });
    },
  },
};
