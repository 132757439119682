import { useState, createContext, useCallback, useMemo } from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarContext = createContext({
  showSnackbar: () => null,
  hideSnackbar: () => null,
});

const initialState = {
  open: false,
  severity: 'success',
  message: '',
};

function SnackbarProvider({ children }) {
  const [state, setState] = useState(initialState);

  const hideSnackbar = useCallback(() => {
    // need to use a functional update because of the closure
    // otherwise the function will not have access to the updated state
    setState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const showSnackbar = useCallback(({ message, severity }) => {
    setState({ severity, message, open: true });
  }, []);

  const contextValue = useMemo(() => ({ showSnackbar, hideSnackbar }));

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar open={state.open} autoHideDuration={4000} onClose={hideSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MuiAlert elevation={6} variant='filled' onClose={hideSnackbar} severity={state.severity}>
          {state.message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export { SnackbarProvider };
export default SnackbarContext;
