import dateFormat from 'date-fns/format';

import api from '../helpers';

const mapper = (model) => ({
  ...model,
  createdAt: dateFormat(new Date(model.createdAt), 'M/dd/yyyy kk:mm'),
});

export default {
  list: {
    key: 'fetchNotifications',
    query: () => {
      return api.get('/api/my/notifications', {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => data.map(mapper),
  },
  seen: {
    key: 'seenNotification',
    query: (id) => {
      return api.put(`/api/my/notifications/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          isSeen: true,
        },
        _throwError: true,
      });
    },
  },
  seenAll: {
    key: 'seenAllNotifications',
    query: () => api.post('/api/my/notifications/seen', { _throwError: true }),
  },
  clearAll: {
    key: 'clearAllNotifications',
    query: () => api.post('/api/my/notifications/clear', { _throwError: true }),
  },
};
