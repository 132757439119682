import api from '../helpers';

export default {
  get: {
    key: 'fetchFacility',
    query: (companyId) => {
      return api.get(`/api/facility/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  create: {
    key: 'addFacility',
    query: (data) => {
      return api.post('/api/facility', {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        _throwError: true,
      });
    },
  },
  edit: {
    key: 'editFacility',
    query: (id, data) => {
      return api.put(`/api/facility/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        _throwError: true,
      });
    },
  },
  delete: {
    key: 'deleteFacility',
    query: (id) => {
      return api.delete(`/api/facility/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
};
