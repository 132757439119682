import api from '../helpers';
import { sortBy } from 'lodash';

export default {
  list: {
    key: 'fetchCountries',
    query: () => {
      return api.get('/api/countries', {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => {
      return sortBy(data, (item) => item.name?.toLowerCase());
    },
  },
};
