import api from '../helpers';

export default {
  get: {
    key: 'fetchNameplate',
    query: (compressorId) => {
      return api.get(`/api/nameplates/${compressorId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  create: {
    key: 'createNameplate',
    query: ({ compressorId, filename, file }) => {
      return api.post('/api/nameplates', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          compressorId,
          filename,
          image: file,
        },
        _throwError: true,
      });
    },
  },
  delete: {
    key: 'deleteNameplate',
    query: (id) => {
      return api.delete(`/api/nameplates/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
};
