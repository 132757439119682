import api from '../helpers';

export default {
  passwordResetRequest: {
    key: 'postPasswordResetRequest',
    query: (email) => {
      return api.post('/api/reset-user-password', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          useCodeUrl: `${window.location.origin}/reset-password?mode=resetPassword`,
        },
      });
    },
  },
  passwordResetCode: {
    key: 'postPasswordResetCode',
    query: (code, password) => {
      return api.post(`/api/reset-user-password/${code}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          password,
        },
      });
    },
  },
  list: {
    key: 'fetchUsers',
    query: () => {
      return api.get('/api/users', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { offset: 0, limit: -1 },
        _throwError: true,
      });
    },
    mapper: ({ data }) => data,
  },
  create: {
    key: 'createUser',
    query: (firstName, lastName, email, companyId, role, password) => {
      return api.post('/api/users', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          firstName,
          lastName,
          email,
          companyId,
          role,
          password,
        },
        _throwError: true,
      });
    },
  },
  change: {
    key: 'changeUser',
    query: (userId, properties) => {
      const data = ['firstName', 'lastName', 'companyId', 'role', 'isActive'].reduce((acc, field) => {
        if (Object.prototype.hasOwnProperty.call(properties, field) && typeof properties[field] !== 'undefined') {
          return { ...acc, [field]: properties[field] };
        }
        return acc;
      }, {});

      return api.put(`/api/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        _throwError: true,
      });
    },
  },
  delete: {
    key: 'deleteUser',
    query: (userId) => {
      return api.delete(`/api/users/${userId}`, {
        _throwError: true,
      });
    },
  },
  editUserPassword: {
    key: 'editUserPassword',
    query: (id, password) => {
      return api.put(`/api/users/${id}/password`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { password },
        _throwError: true,
      });
    },
  },
  addProfilePhoto: {
    key: 'addProfilePhoto',
    query: (photo) => {
      return api.post('/api/my/photo', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          photo,
        },
        _throwError: true,
      });
    },
  },
  updatePassword: {
    key: 'updatePassword',
    query: (currentPassword, newPassword) => {
      return api.post('/api/my/password', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          currentPassword,
          newPassword,
        },
        _throwError: true,
      });
    },
  },
  getAccountQuota: {
    key: 'fetchAccountQuota',
    query: (accountId) => {
      return api.get(`/api/users/${accountId}/quota`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
  setAccountQuota: {
    key: 'setAccountQuota',
    query: (accountId, quota, used, threshold) => {
      return api.put(`/api/users/${accountId}/quota`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          quota,
          used,
          threshold,
        },
        _throwError: true,
      });
    },
  },
};
