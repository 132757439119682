import dateFormat from 'date-fns/format';
import DISPLAY_TYPE from 'src/constants/displayType';
import { RECORD_FIELD_NAMES } from 'src/constants/recordFields';
import { t } from 'i18next';

export const roundNumbers = (value, toDecimal = 2) => {
  if (!Number.isNaN(value) || value === 0) {
    const multiplier = 10 ** toDecimal;
    return multiplier === 0 ? parseInt(value, 10) : (Math.round((value + Number.EPSILON) * multiplier) / multiplier).toFixed(toDecimal);
  }

  return value || 0.0;
};

export const formatDates = (date) => {
  if (date?.seconds) {
    return dateFormat(date.toDate(), 'PPpp');
  }

  if (date instanceof Date || Number.isInteger(date)) {
    return dateFormat(date, 'PPpp');
  }

  if (typeof date === 'number') {
    return dateFormat(date, 'PPpp');
  }

  return '-';
};

export const displayValuesByField = (field, value, recordPath) => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    switch (field) {
      case recordPath.AMBIENT_TEMPERATURE:
      case recordPath.LEAK_PRESSURE:
      case recordPath.LEAK_TEMPERATURE:
        return Math.round(value);

      case recordPath.COMPRESSOR_BHP:
      case recordPath.MOTOR_EFFICIENCY:
      case recordPath.ALTITUDE:
      case recordPath.CAPACITY:
      case recordPath.POWER_LOAD:
        return value.toFixed(1);

      case recordPath.ENERGY_COST:
      case recordPath.DISTANCE:
      case recordPath.DOLLARS_PER_YEAR:
      case recordPath.COMPRESSOR_FLOW:
      case recordPath.KWH_PER_YEAR:
        return value.toFixed(2);

      case recordPath.GAS_COST:
        return value.toFixed(8);

      case recordPath.FLOW:
        return value.toFixed(4);

      default:
        return value || 0.0;
    }
  }

  if (typeof value === 'boolean') {
    switch (field) {
      case recordPath.ACTIVE:
        return value === true ? t('views.companies.commonUsedWords.activated') : t('views.companies.commonUsedWords.deactivated');

      default:
        return value;
    }
  }

  return value;
};

export function getDisplayValue(type, value) {
  switch (type) {
    case DISPLAY_TYPE.DATE:
      return formatDates(value);

    case DISPLAY_TYPE.ROUNDED_NUMBER:
      return roundNumbers(value);

    case DISPLAY_TYPE.ARRAY:
      return (value || []).join(', ');

    case DISPLAY_TYPE.BOOLEAN:
      return value ? t('commonUsedWords.yes') : t('commonUsedWords.no');

    default:
      return value;
  }
}

export function displayColumnValues(val, columnName, record) {
  if (!val) {
    return '';
  }
  const value = val && val[columnName];

  const columnsWithCurrency = [
    RECORD_FIELD_NAMES.GAS_COST,
    RECORD_FIELD_NAMES.ENERGY_COST,
    RECORD_FIELD_NAMES.LOSS_PER_YEAR,
    RECORD_FIELD_NAMES.DOLLARS_PER_YEAR,
  ];

  if (columnsWithCurrency.includes(columnName)) {
    return `${record.currency} ${value}`;
  }

  return value;
}

// if the value is a set value
export const isDefined = (value) => {
  return value != null && value !== '' && !isNaN(value);
};

// generate preview image
export const generatePreviewImage = (image, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(image);
  reader.onload = () => {
    cb(reader.result);
  };
};
