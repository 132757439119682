import DISPLAY_TYPE from './displayType';

export const RECORD_FIELD_NAMES = {
  CREATED_AT: 'CREATED_AT',
  RECORD_ID: 'RECORD_ID',
  DOCUMENT_ID: 'DOCUMENT_ID',
  GAS_COMPONENT: 'GAS_COMPONENT',
  GAS_COST: 'GAS_COST',
  DATE_TIME: 'DATE_TIME',
  COMPANY: 'COMPANY',
  USER: 'USER',
  CLIENT: 'CLIENT',
  FLOW: 'FLOW',
  FACILITY: 'FACILITY',
  SERVICE_TYPE: 'SERVICE_TYPE',
  DB_PEAK: 'DB_PEAK',
  NOTES: 'NOTES',
  EQUIPMENT_TAG: 'EQUIPMENT_TAG',
  EQUIPMENT_TYPE: 'EQUIPMENT_TYPE',
  DOLLARS_PER_YEAR: 'DOLLARS_PER_YEAR',
  LEAK_TYPE: 'LEAK_TYPE',
  LEAK_TEMPERATURE: 'LEAK_TEMPERATURE',
  LEAK_PRESSURE: 'LEAK_PRESSURE',
  LEAK_CATEGORY: 'LEAK_CATEGORY',
  DISTANCE: 'DISTANCE',
  BUILDING: 'BUILDING',
  PROJECT_SURVEY: 'PROJECT_SURVEY',
  OUTAGE_STATUS: 'OUTAGE_STATUS',
  ACTION_STATUS: 'ACTION_STATUS',
  ACTION_STATUS_MODIFIED: 'ACTION_STATUS_MODIFIED',
  REQUIRED_ACTION: 'REQUIRED_ACTION',
  DB_ACTIVE: 'DB_ACTIVE',
  REFERENCE_ID: 'REFERENCE_ID',
  LEAK_TAG: 'LEAK_TAG',
  REPAIRED_BY: 'REPAIRED_BY',
  DEVICE_ID: 'DEVICE_ID',
  AMBIENT_TEMPERATURE: 'AMBIENT_TEMPERATURE',
  COMPRESSOR_BHP: 'COMPRESSOR_BHP',
  COMPRESSOR_FLOW: 'COMPRESSOR_FLOW',
  COMPRESSOR_TYPE: 'COMPRESSOR_TYPE',
  MOTOR_EFFICIENCY: 'MOTOR_EFFICIENCY',
  ENERGY_COST: 'ENERGY_COST',
  HOURS: 'HOURS',
  LOCATION: 'LOCATION',
  LAST_MODIFIED: 'LAST_MODIFIED',
  POWER_LOAD: 'POWER_LOAD',
  CAPACITY: 'CAPACITY',
  SERVICE: 'SERVICE',
  ALTITUDE: 'ALTITUDE',
  FREQUENCY_LOW_BOUND: 'FREQUENCY_LOW_BOUND',
  FREQUENCY_HIGH_BOUND: 'FREQUENCY_HIGH_BOUND',
  SENSITIVITY: 'SENSITIVITY',
  KWH_PER_YEAR: 'KWH_PER_YEAR',
  ASSIGNED_DATE: 'ASSIGNED_DATE',
  ASSIGNED_BY: 'ASSIGNED_BY',
  ASSIGNED_TO: 'ASSIGNED_TO',
  LOSS_PER_YEAR: 'LOSS_PER_YEAR',
  CALCULATION_VERSION: 'CALCULATION_VERSION',
  CREATOR_USER_ID: 'CREATOR_USER_ID',
  CURRENCY: 'CURRENCY',
  COMPLETED_ON: 'COMPLETED_ON',
};

export const RECORD_FIELD_PATHS = {
  CREATED_AT: 'createdAt',
  RECORD_ID: 'documentId',
  DOCUMENT_ID: 'documentId',
  DEVICE_ID: 'deviceId',
  DOLLARS_PER_YEAR: 'liveDataReading.lossPerYear',
  GAS_COMPONENT: 'leakParameters.mainComponent',
  GAS_COST: 'leakParameters.gasCost',
  ENERGY_COST: 'leakParameters.energyCost',
  LEAK_TYPE: 'leakSource.leakType',
  LEAK_TEMPERATURE: 'leakParameters.componentTemperature',
  LEAK_PRESSURE: 'leakParameters.componentPressure',
  LEAK_CATEGORY: 'leakStatus.category',
  AMBIENT_TEMPERATURE: 'leakParameters.ambientTemperature',
  DISTANCE: 'liveDataReading.distance',
  DATE_TIME: 'deviceCreatedAt',
  LAST_MODIFIED: 'lastModified',
  DB_PEAK: 'liveDataReading.dbPeak',
  COMPANY: 'companyName',
  NOTES: 'leakSource.notes',
  USER: 'creator',
  CLIENT: 'leakStatus.client',
  EQUIPMENT_TAG: 'leakSource.equipmentTag',
  EQUIPMENT_TYPE: 'leakSource.equipmentType',
  FLOW: 'liveDataReading.massFlow',
  BUILDING: 'leakStatus.building',
  PROJECT_SURVEY: 'leakStatus.surveyRecordId',
  OUTAGE_STATUS: 'leakStatus.outageStatus',
  FACILITY: 'leakStatus.facility',
  LOCATION: 'leakStatus.location',
  REQUIRED_ACTION: 'leakStatus.requiredAction',
  ACTION_STATUS: 'leakStatus.actionStatus',
  ACTION_STATUS_MODIFIED: 'leakStatus.actionStatusModified',
  LEAK_TAG: 'leakStatus.leakTag',
  REPAIRED_BY: 'leakStatus.repairedBy',
  SERVICE_TYPE: 'leakSource.serviceType',
  DB_ACTIVE: 'liveDataReading.dbActive',
  REFERENCE_ID: 'leakStatus.surveyRecordId',
  HOURS: 'leakParameters.hoursPerYear',
  COMPRESSOR_FLOW: 'leakParameters.compressorFlow',
  COMPRESSOR_BHP: 'leakParameters.compressorBHP',
  COMPRESSOR_TYPE: 'leakParameters.compressorType',
  MOTOR_EFFICIENCY: 'leakParameters.motorEfficiency',
  POWER_LOAD: 'leakParameters.powerLoad',
  CAPACITY: 'leakParameters.capacity',
  ALTITUDE: 'leakStatus.gpsAltitude',
  FREQUENCY_LOW_BOUND: 'liveDataReading.frequencyLowBound',
  FREQUENCY_HIGH_BOUND: 'liveDataReading.frequencyHighBound',
  SENSITIVITY: 'liveDataReading.sensitivity',
  KWH_PER_YEAR: 'liveDataReading.kwhPerYear',
  IMAGES: 'leakSource.imageData',
  ASSIGNED_BY: 'assignedBy.email',
  ASSIGNED_TO: 'assignedTo.email',
  ASSIGNED_DATE: 'assignedAt',
  FFT: 'liveDataReading.fft',
  CALCULATION_VERSION: 'liveDataReading.calculationVersion',
  CREATOR_USER_ID: 'creatorUserId',
  CURRENCY: 'currency',
  COMPLETED_ON: 'leakStatus.actionStatusModified',
};

export const DEFAULT_LABELS = {
  CREATED_AT: 'Created At',
  RECORD_ID: 'Record ID Number',
  DOCUMENT_ID: 'Record ID Number',
  DOLLARS_PER_YEAR: 'Loss',
  GAS_COMPONENT: 'Gas/Component',
  LEAK_TYPE: 'Leak Type Profile',
  DATE_TIME: 'Date/Time',
  DB_PEAK: 'Peak dB',
  COMPANY: 'Company',
  NOTES: 'Notes',
  USER: 'User',
  EQUIPMENT_TAG: 'Equipment/Component Tag',
  FLOW: 'Flow (m³/h)',
  BUILDING: 'Unit/Building/Machine',
  PROJECT_SURVEY: 'Audit/Survey Record Ref.',
  OUTAGE_STATUS: 'Outage/Shutdown Required?',
  FACILITY: 'Facility/Site',
  ACTION_STATUS: 'Action Status',
  SERVICE_TYPE: 'Service/Type',
  DB_ACTIVE: 'dB Active',
  REFERENCE_ID: 'Project #/Survey ID',
  AMBIENT_TEMPERATURE: 'Ambient Temperature (°C)',
  LEAK_PRESSURE: 'Pressure (kPag)',
  LEAK_TEMPERATURE: 'Gas Temperature (°C)',
  LEAK_CATEGORY: 'Leak Category',
  DISTANCE: 'Distance (m)',
  GAS_COST: 'Cost of Gas ($/m³)',
  COMPRESSOR_FLOW: 'm³/h per BHP',
  DEVICE_ID: 'Device ID',
  COMPRESSOR_BHP: 'Compressor BHP',
  COMPRESSOR_TYPE: 'Compressor Type',
  MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
  ENERGY_COST: 'Energy Cost (per kWh)',
  HOURS: 'Hours Running/Year',
  LOCATION: 'Leak Location',
  LAST_MODIFIED: 'Last Saved Date',
  POWER_LOAD: '% Full Load Power',
  CLIENT: 'Client',
  SERVICE: 'Service',
  CAPACITY: '% Capacity',
  EQUIPMENT_TYPE: 'Equipment/Component Type',
  LEAK_TAG: 'Leak Tag #/Work Ref. #',
  REPAIRED_BY: 'Repaired By',
  ALTITUDE: 'Altitude (m)',
  REQUIRED_ACTION: 'Required Action',
  FREQUENCY_LOW_BOUND: 'Frequency (Low)',
  FREQUENCY_HIGH_BOUND: 'Frequency (High)',
  SENSITIVITY: 'Sensitivity',
  KWH_PER_YEAR: 'kWh/year',
  ASSIGNED_BY: 'Assigned By',
  ASSIGNED_TO: 'Assigned To',
  ASSIGNED_DATE: 'Assignment Date',
  LOSS_PER_YEAR: 'Loss (per year)',
  ASSIGNED: 'Assigned',
  CREATOR_USER_ID: 'User ID',
  CURRENCY: 'Currency',
  COMPLETED_ON: 'Completed On',
  IMAGES: 'Leak Source Images',
  ACTION_STATUS_MODIFIED: 'Action Status Modified',
  NAMEPLATE_CAPACITY: 'Nameplate Capacity (m³/h)',
  FULL_LOAD_INPUT_POWER: 'Full Load Input Power (kW)',
  RATED_PRESSURE: 'Rated Pressure (kPag)',
  PRESSURE_SETPOINT: 'Pressure Setpoint (kPag)',
  TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size (l)',
  COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint (kPag)',
  COMPRESSOR_FLOW_SEU: 'Compressor Flow (m³/h)',
  SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint (kPag)',
  SYSTEM_MEASURED_FLOW: 'System Measured Flow (m³/h)',
  VOL_FLOW_FOUND: 'Volume Flow Found (m³/h)',
  VOL_FLOW_REPAIRED: 'Volume Flow Repaired (m³/h)',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found) (kWh)',
  ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed) (kWh)',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced (kPag)',
};

export const IMPERIAL_LABELS = {
  FLOW: 'Flow (cfm)',
  AMBIENT_TEMPERATURE: 'Ambient Temperature (°F)',
  LEAK_PRESSURE: 'Pressure (psi)',
  LEAK_TEMPERATURE: 'Gas Temperature (°F)',
  DISTANCE: 'Distance (ft)',
  GAS_COST: 'Cost of Gas ($/ft³)',
  COMPRESSOR_FLOW: 'cfm per BHP',
  ALTITUDE: 'Altitude (ft)',
  NAMEPLATE_CAPACITY: 'Nameplate Capacity (cfm)',
  FULL_LOAD_INPUT_POWER: 'Full Load Input Power (BTU/h)',
  RATED_PRESSURE: 'Rated Pressure (psi)',
  PRESSURE_SETPOINT: 'Pressure Setpoint (psi)',
  TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size (gal)',
  COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint (psi)',
  COMPRESSOR_FLOW_SEU: 'Compressor Flow (cfm)',
  SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint (psi)',
  SYSTEM_MEASURED_FLOW: 'System Measured Flow (cfm)',
  VOL_FLOW_FOUND: 'Volume Flow Found (cfm)',
  VOL_FLOW_REPAIRED: 'Volume Flow Repaired (cfm)',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found) (BTU)',
  ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed) (BTU)',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced (psi)',
};

export const RECORD_COLUMN_TYPES = {
  DOLLARS_PER_YEAR: DISPLAY_TYPE.ROUNDED_NUMBER,
  DATE_TIME: DISPLAY_TYPE.DATE,
  LAST_MODIFIED: DISPLAY_TYPE.DATE,
  FLOW: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_FLOW: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_BHP: DISPLAY_TYPE.ROUNDED_NUMBER,
  POWER_LOAD: DISPLAY_TYPE.ROUNDED_NUMBER,
  OUTAGE_STATUS: DISPLAY_TYPE.BOOLEAN,
  DB_ACTIVE: DISPLAY_TYPE.ROUNDED_NUMBER,
  DB_PEAK: DISPLAY_TYPE.ROUNDED_NUMBER,
  ENERGY_COST: DISPLAY_TYPE.ROUNDED_NUMBER,
  AMBIENT_TEMPERATURE: DISPLAY_TYPE.ROUNDED_NUMBER,
  LEAK_PRESSURE: DISPLAY_TYPE.ROUNDED_NUMBER,
  DISTANCE: DISPLAY_TYPE.ROUNDED_NUMBER,
  KWH_PER_YEAR: DISPLAY_TYPE.ROUNDED_NUMBER,
  ASSIGNED_DATE: DISPLAY_TYPE.DATE,
  ALTITUDE: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPLETED_ON: DISPLAY_TYPE.DATE,
  CREATED_AT: DISPLAY_TYPE.DATE,
};

export const RECORD_COLUMN_WIDTHS = {
  GAS_COMPONENT: 150,
  DB: 150,
  NOTES: 300,
  EQUIPMENT_TAG: 250,
};

export const UNIT_FIELDS = [
  RECORD_FIELD_NAMES.AMBIENT_TEMPERATURE,
  RECORD_FIELD_NAMES.LEAK_PRESSURE,
  RECORD_FIELD_NAMES.LEAK_TEMPERATURE,
  RECORD_FIELD_NAMES.DISTANCE,
  RECORD_FIELD_NAMES.GAS_COST,
  RECORD_FIELD_NAMES.COMPRESSOR_FLOW,
  RECORD_FIELD_NAMES.COMPRESSOR_BHP,
  RECORD_FIELD_NAMES.FLOW,
];
