import api from '../helpers';

export default {
  create: {
    key: 'createAudit',
    query: (id, comment) => {
      return api.post('/api/audits', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id,
          comment,
        },
        _throwError: true,
      });
    },
  },
  list: {
    key: 'fetchAuditsByCompany',
    query: (companyId) => {
      return api.get(`/api/audits/company/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  get: {
    key: 'fetchAuditByLeak',
    query: (leakId) => {
      return api.get(`/api/audits/leak/${leakId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  updateComment: {
    key: 'updateAuditComment',
    query: (id, comment) => {
      return api.put(`/api/audits/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          comment,
        },
        _throwError: true,
      });
    },
  },
  delete: {
    key: 'deleteAudit',
    query: (id) => {
      return api.delete(`/api/audits/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
};
