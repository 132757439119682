import api from '../helpers';

const mapper = ({ data }) => {
  return data.map((model) => ({
    // eslint-disable-next-line no-underscore-dangle
    id: model._id || model.id,
    ...model,
  }));
};

export default {
  create: {
    key: 'createImage',
    query: (documentId, filename, image) => {
      return api.post('/api/images', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          documentId,
          filename,
          image,
        },
        _throwError: true,
      });
    },
  },
  listByDocumentId: {
    key: 'fetchImagesByDocumentId',
    query: (documentId) => {
      return api.get(`/api/images/${documentId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          by: 'documentId',
        },
        _throwError: true,
      });
    },
    mapper,
  },
  listByAssignmentId: {
    key: 'fetchImagesByAssignmentId',
    query: (assignmentId) => {
      return api.get(`/api/images/${assignmentId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          by: 'assignmentId',
        },
        _throwError: true,
      });
    },
    mapper,
  },
  getByDocumentIdList: {
    key: 'fetchImagesByDocumentIdList',
    query: (documentId) => {
      return api.post('/api/list-images', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          documentId,
        },
        _throwError: true,
      });
    },
    mapper: ({ data }) => {
      return data.reduce((acc, { id, documentId, webPath }) => {
        if (acc[documentId]) {
          return {
            ...acc,
            [documentId]: [...acc[documentId], { id, leakImageUrl: `${webPath}?q=${Date.now()}` }],
          };
        }

        return { ...acc, [documentId]: [{ id, leakImageUrl: `${webPath}?q=${Date.now()}` }] };
      }, {});
    },
  },
  delete: {
    key: 'deleteImage',
    query: (id) => {
      return api.delete(`/api/images/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
  createLogo: {
    key: 'createLogo',
    query: (companyId, filename, image) => {
      return api.post('/api/images/logo', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          companyId,
          filename,
          image,
        },
        _throwError: true,
      });
    },
  },
  getLogo: {
    key: 'getLogo',
    query: (companyId) => {
      return api.get(`/api/images/logo/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
  deleteLogo: {
    key: 'deleteLogo',
    query: (companyId) => {
      return api.delete(`/api/images/logo/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
  createCompressorFile: {
    key: 'createCompressorFile',
    query: ({ compressorId, filename, file, fileComponent }) => {
      return api.post(`/api/files/compressor/${compressorId}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          filename,
          file,
          fileComponent,
        },
        _throwError: true,
      });
    },
  },
  getCompressorFiles: {
    key: 'getCompressorFiles',
    query: (compressorId) => {
      return api.get(`/api/files/compressor/${compressorId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => model);
    },
  },
  deleteCompressorFile: {
    key: 'deleteCompressorFile',
    query: (fileId) => {
      return api.delete(`/api/files/compressor/${fileId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
};
