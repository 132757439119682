import axios, { AxiosError } from 'axios';
import { parse, stringify } from 'qs';
import { t } from 'i18next';

import STORAGE from 'src/constants/localStorage';

const getAccessToken = () => {
  return [localStorage.getItem(STORAGE.TOKEN.ACCESS), localStorage.getItem(STORAGE.TOKEN.ACCESS_EXPIRES_AT)];
};

const setAccessToken = (token) => {
  localStorage.setItem(STORAGE.TOKEN.ACCESS, token);
};

const getRefreshToken = () => {
  return [localStorage.getItem(STORAGE.TOKEN.REFRESH), localStorage.getItem(STORAGE.TOKEN.REFRESH_EXPIRES_AT)];
};

const setRefreshToken = (token) => {
  localStorage.setItem(STORAGE.TOKEN.REFRESH, token);
};

const setAuth = (headers) => {
  const [token] = getAccessToken();

  if (typeof token === 'undefined' || token === null) {
    return headers;
  }

  return { ...headers, Authorization: `Bearer ${token}` };
};

const checkStatus = (response) => {
  // todo : check HTTP status codes (401, 404, 500 etc.)
  if (![200, 201].includes(response.status)) {
    throw new Error(`${t('api.helpers.error')}: ${response.status} ${response.statusText}`);
  }
};

const sendRequest = async (method, url, options = {}) => {
  if (!['get', 'post', 'put', 'delete'].includes(method)) {
    throw new Error(`${t('api.helpers.invalidHTTPMethod')} - ${method}`);
  }

  const { _throwError, responseType = 'json', ...reqOptions } = options;

  const result = { data: null, error: null, headers: null };
  try {
    // Add authorization token
    if (!reqOptions.headers?.Authorization) {
      reqOptions.headers = setAuth(reqOptions.headers || {});
    }

    const response = await axios({
      url,
      method,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      paramsSerializer: { encode: parse, serialize: stringify },
      responseType,
      ...reqOptions,
    });
    checkStatus(response);

    result.data = response.data;
    result.headers = response.headers;
  } catch (err) {
    if (err instanceof AxiosError) {
      console.error(err.message);
      console.error(err.cause);
    }
    // Write error details in browser console
    result.error = err;
    if (err.response && err.response.data && err.response.data.error) {
      // Overwrite axios error with API one
      result.error = err.response.data.error;
    }

    if (_throwError) {
      // Throw error flag is used for react-query mutations
      throw result.error;
    }
  }

  return result;
};

export default {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  clearTokens: () => {
    setAccessToken(null);
    setRefreshToken(null);
  },
  get: (url, options) => {
    return sendRequest('get', url, options);
  },
  post: (url, options) => {
    return sendRequest('post', url, options);
  },
  put: (url, options) => {
    return sendRequest('put', url, options);
  },
  delete: (url, options) => {
    return sendRequest('delete', url, options);
  },
};
