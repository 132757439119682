import api from './helpers';
import users from './queries/users';
import companies from './queries/companies';
import leaks from './queries/leaks';
import notifications from './queries/notifications';
import assignments from './queries/assignments';
import images from './queries/images';
import filters from './queries/filters';
import devices from './queries/devices';
import countries from './queries/countries';
import languages from './queries/languages';
import industries from './queries/industries';
import facility from './queries/facility';
import audits from './queries/audits';
import compressors from './queries/compressors';
import nameplates from './queries/nameplates';
import datasheets from './queries/datasheets';
import enpis from './queries/enpis';
import history from './queries/history';
import firmware from './queries/firmware';

export default {
  auth: {
    // for legacy
    registerCode: {
      key: 'createAccount',
      query: (params) => {
        return api.post('/api/create-user', {
          data: params,
        });
      },
    },
    registerSerial: {
      key: 'createAccount',
      query: ({ firstName, lastName, email, password, serial }) => {
        return api.post('/api/create-user-serial', {
          data: {
            firstName,
            lastName,
            email,
            password,
            serial,
          },
        });
      },
    },
    authorize: {
      key: 'fetchAccessToken',
      query: (username, password, serial) => {
        return api.post('/api/auth/token', {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            username,
            password,
            serial,
            platform: 'dashboard',
          },
        });
      },
    },
    authorizeUsingKey: {
      key: 'fetchAccessToken',
      query: (key) => {
        return api.post('/api/auth/token/integration', {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            key,
            secret: process.env.REACT_APP_DASHBOARD_INTEGRATION_SECRET_KEY,
          },
        });
      },
    },
    refresh: {
      key: 'fetchRefreshToken',
      query: (refreshToken) => {
        return api.get('/api/auth/token/refresh', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${refreshToken}`,
          },
        });
      },
    },
  },
  me: {
    key: 'fetchMe',
    query: () => {
      return api.get('/api/me', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  myQuota: {
    key: 'fetchMyQuota',
    query: () => {
      return api.get('/api/my/quota', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper: ({ data }) => {
      return Object.keys(data.quota).reduce((acc, key) => {
        const exceeded = typeof data.quota[key].used !== 'undefined' && data.quota[key].used === data.quota[key].threshold;
        return { ...acc, [key]: exceeded };
      }, {});
    },
  },
  isTranslationFeatureActive: {
    key: 'isTranslationFeatureActive',
    query: () => {
      return api.get('/api/feature/check-translation', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  getFeatureViewable: {
    key: 'getFeatureViewable',
    query: (id) => {
      return api.get(`/api/features/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  roles: {
    key: 'fetchRoles',
    query: () => {
      return api.get('/api/roles', {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
  users,
  companies,
  leaks,
  notifications,
  assignments,
  images,
  filters,
  devices,
  countries,
  languages,
  industries,
  facility,
  audits,
  compressors,
  nameplates,
  datasheets,
  enpis,
  history,
  firmware,
};
